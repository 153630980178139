import React from 'react';
import ReactDOM from 'react-dom/client';
import obfuscator from '@banking/web-obfuscator';
import { recordPerformance } from '@banking/web-sdk/utils/performance';
import { initAPMS } from '@utils/apms';

import App from './App';
import './utils/init-secure-fetch';
import './core/globalError';
import { initWebLog } from '@utils/log';

// need to load jsbridge when runs in shopee webview, or 'can't find variable' error will pop
import './assets/jsbridge';

initAPMS('sg-hybrid-site');
initWebLog('sg-hybrid-site');

const { verifyHtml, canSupportWasm } = obfuscator;

async function render() {
  if (canSupportWasm()) {
    import('@banking/web-sdk/utils/init-wasm');
  } else {
    import('@banking/web-sdk/utils/init-login');
  }

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  if (process.env.NODE_ENV === 'production') {
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
    );
  } else {
    root.render(<App />);
  }
}

if (process.env.NODE_ENV === 'production' && process.env.SECURE_HTML === 'true') {
  recordPerformance({
    type: 'verifyHtml',
    startTimestamp: new Date().valueOf(),
  });

  verifyHtml().then(() => {
    recordPerformance({
      type: 'verifyHtml',
      endTimestamp: new Date().valueOf(),
    });

    render();
  });
} else {
  render();
}
