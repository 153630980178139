import WebLog from '@banking/web-log';

const NonliveList = ['dev1', 'dev2', 'sit1', 'sit2', 'sit3', 'uat1', 'uat2', 'uat3'];
// 默认走live
const reportUrl =
  process.env.env && NonliveList.includes(process.env.env)
    ? 'https://apm-uat1.uat.npt.maribank.com.sg/xlog/v1/web/log/real_time'
    : 'https://apm.maribank.com.sg/xlog/v1/web/log/real_time';

export const initWebLog = (project: string) => {
  WebLog.setOptions({
    debug: true,
    logLevel: 'info',
    realTimeUrl: reportUrl,
    project,
    publicKey: `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC6TdE2VpxPTtuN2kwRmaJ42dozf8DKDIdP/byN7ZyhanMzVTk67Z0qUy8VGhxlsOYIMkThqITNaArJdSHLFD1hPO2nThjF3qyOW+IXMU4zkwmrCMqSYaNrw4iTgAeNRM10FYL7x16FyCnP9JksG4UFCPHaHmzHxSVDQWhewY7ZpwIDAQAB`,
  });
  WebLog.info(`Project ${project} init!`);
};
